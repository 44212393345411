<template>
  <v-container fluid
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd">
    <v-row>
      <v-col>
        <div class="article" v-if="title">
          <div class="title">{{ title }}</div>
          <div v-if="link != '#'" class="link">
            <a :href="link">{{ author + '@' + site }}</a>
          </div>
          <div class="content" v-html="content" @dblclick="like" @touchend="handleTouch"></div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000" top>
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: "Read",
  data() {
    return {
      random: false, // 是否是随机模式，true为随机模式，false为顺序模式
      id: '',
      title: '',
      author: '',
      site: '',
      content: '',
      link: '#',
      changing: false,
      snackbar: false,
      snackbarColor: '',
      snackbarText: '',
      touchStartX: 0,
      touchEndX: 0,
      touchTime: 0,
    }
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      this.random = false
      this.id = this.$route.params.id * 1
      this.fetchArticle('https://qidafang.com/api/readArticle/' + this.id)
    } else {
      this.random = true
      let url = 'https://qidafang.com/api/randomArticle'
      this.fetchArticle(url)
    }
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].clientX;
      if (Math.abs(this.touchEndX - this.touchStartX) > 200) { // 你可以根据需要调整这个值
        this.fetchArticle('https://qidafang.com/api/randomArticle');
      }
    },
    handleTouch() {
      let now = new Date().getTime();
      let timesince = now - this.touchTime;
      if ((timesince < 600) && (timesince > 0)) {
        // 双击事件
        this.like();
      } else {
        // 单击事件
        // 你可以在这里添加单击事件的处理代码，如果你需要的话
      }
      this.touchTime = new Date().getTime();
    },
    fetchArticle(url) {
      axios.get(url)
        .then(response => {
          let json = response.data;
          this.id = json.id * 1
          this.title = json.title
          this.author = json.author
          this.site = json.site
          this.content = json.text
          this.link = json.link

          this.changing = false
          document.body.scrollTop = document.documentElement.scrollTop = 0
        })
        .catch(error => console.error(error));
    },
    like() {
      axios.post('https://qidafang.com/api/story/friendArticles/love/' + this.id)
        .then(response => {
          let json = response.data;
          if (json.code == 'success') {
            this.snackbarColor = 'success';
            this.snackbarText = json.data;
            this.snackbar = true;
          } else {
            this.snackbarColor = 'error';
            this.snackbarText = json.data;
            this.snackbar = true;
          }
        })
        .catch(error => window.console.error(error));
    }
  }
};
</script>

<style scoped>
html {
  touch-action: none;
}

.title {
  margin-top: 15px;
  margin-left: 0;
  font-size: 120%;
  font-weight: 600;
}

.link {
  margin-top: 15px;
}

.content {
  margin-top: 30px;
}
</style>
